<!--
 * @ Author: Lmi
 * @ Create Time: 2023-09-05 15:00:55
 * @ Modified by: Your name
 * @ Modified time: 2023-09-05 15:00:55
 * @ Description: 数据统计>平台统计>消费充电统计 
 -->

<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="consumer-charging-statistics">
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns">
        <template #headerButtons>
          <div class="table-header-button">
            <template v-for="(btn, i) in pageButtons">
              <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handleClick">
                {{ btn.text }}
              </ykc-button>
            </template>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
    </div>
  </scroll-layout>
</template>

<script>
  import { offlineExport, code, getYesdayMonth } from '@/utils';
  import { dataStatisticsServe, barrierPage } from '@/service/apis';

  export default {
    name: 'consumerChargingStatisticsList',

    data() {
      return {
        pageButtons: [
          {
            id: '0',
            text: '导出',
            type: 'plain',
            enabled: () => code('consumer:charging:export'),
            handleClick: () => {
              this.doExportFile();
            },
          },
        ],
        tableData: [],
        tableColumns: [
          { label: '日期', prop: 'date', minWidth: '150px' },
          { label: '充电次数', prop: 'chargingCount', minWidth: '100px' },
          { label: '充电总电量', prop: 'totalChargedPower', minWidth: '100px' },
          { label: '应付总金额', prop: 'totalPaymentAmount', minWidth: '100px' },
          { label: '优惠总金额', prop: 'totalDiscountAmount', minWidth: '100px' },
          { label: '消费总金额', prop: 'totalConsumptionAmount', minWidth: '100px' },
          { label: '电价金额', prop: 'elePriceAmount', minWidth: '100px' },
          { label: '服务费金额', prop: 'serviceFeeAmount', minWidth: '100px' },
          { label: '占位费', prop: 'placeholderFee', minWidth: '100px' },
          { label: '尖电量', prop: 'topPower', minWidth: '100px' },
          { label: '峰电量', prop: 'peakPower', minWidth: '100px' },
          { label: '平电量', prop: 'flatPower', minWidth: '100px' },
          { label: '谷电量', prop: 'valleyPower', minWidth: '100px' },
          { label: '尖金额', prop: 'topElectricAmount', minWidth: '100px' },
          { label: '峰金额', prop: 'peakElectricAmount', minWidth: '100px' },
          { label: '平金额', prop: 'flatElectricAmount', minWidth: '100px' },
          { label: '谷金额', prop: 'valleyElectricAmount', minWidth: '100px' },
          { label: '尖服务费', prop: 'topServerAmount', minWidth: '100px' },
          { label: '峰服务费', prop: 'peakServerAmount', minWidth: '100px' },
          { label: '平服务费', prop: 'flatServerAmount', minWidth: '100px' },
          { label: '谷服务费', prop: 'valleyServerAmount', minWidth: '100px' },
        ],
        tableTitle: '明细列表',
        pageInfo: {
          current: 1,
          size: 10,
        },
        stationData: [], // 电站数据
        searchParams: {
          queryDate: '',
          stationId: '',
        },
      };
    },
    created() {
      this.searchParams.queryDate = getYesdayMonth(); // 默认显示年月(获取当前日期前一天所在年-月)
      console.log('---this.searchParams.queryDate', this.searchParams.queryDate);
      this.getStationData();
      this.searchTableList();
    },
    computed: {
      searchData() {
        return [
          {
            label: '时间维度',
            key: 'queryDate',
            comName: 'YkcDatePicker',
            type: 'month',
            format: 'yyyy-MM',
          },
          {
            comName: 'YkcDropdown',
            key: 'stationId',
            label: '电站名称',
            placeholder: '请输入电站名称',
            data: this.stationData,
            remote: true,
          },
        ];
      },
    },
    methods: {
      /**
       * @desc 获取电站数据
       * */
      getStationData() {
        barrierPage.findStationList({}).then(res => {
          this.stationData = (res || []).map(item => {
            return {
              id: item.stationId,
              name: item.stationName,
            };
          });
        });
      },
      /** 根据条件查询数据 */
      searchTableList() {
        dataStatisticsServe
          .consumptionChargingList({
            ...this.pageInfo,
            ...this.searchParams,
          })
          .then(res1 => {
            this.tableData = res1.records;
            this.pageInfo.total = res1.total || 0;
          });
      },
      /**
       * @desc 导出文件 - 消费充电统计列表
       * */
      doExportFile() {
        offlineExport(
          {
            downloadType: 'consumption_charging_statistics',
            jsonNode: {
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, form);
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form, {
          queryDate: getYesdayMonth(), // 清空显示默认年月
        });
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.searchTableList();
      },
    },
  };
</script>
<style lang="scss" scoped></style>
